<template>
  <v-row justify="center" class="loader">
    <v-progress-circular
      :size="70"
      :width="7"
      indeterminate
    ></v-progress-circular>
  </v-row>
</template>

<script>
export default {
  name: "TheLoader",
};
</script>

<style scoped lang="scss">
.loader {
  margin: 50px;
}
</style>

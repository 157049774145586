
import { Component, Vue, Watch } from "vue-property-decorator";
import { getNextLesson, getUserResults } from "@/api/api";
import ILesson from "@/types/lesson";
import TheLoader from "@/components/TheLoader.vue";
import LessonCard from "@/components/LessonCard.vue";
import DashboardCard from "@/components/DashboardCard.vue";
import IUserResult from "@/types/user-result";

@Component({
  components: { DashboardCard, LessonCard, TheLoader },
})
export default class Dashboard extends Vue {
  private nextLesson: ILesson | null = null;
  private userStat: IUserResult | null = null;

  private lessonLoading = true;
  private statsLoading = true;

  private get loading() {
    return this.lessonLoading || this.statsLoading;
  }

  private get userId() {
    return this.$store.getters.userId;
  }

  private async getNextLesson() {
    try {
      this.nextLesson = await getNextLesson();
    } catch (e) {
      console.error(e);
    } finally {
      this.lessonLoading = false;
    }
  }

  private async getUserStats() {
    if (!this.userId) {
      return;
    }

    try {
      this.userStat = await getUserResults(this.userId);
    } catch (e) {
      console.error(e);
    } finally {
      this.statsLoading = false;
    }
  }

  @Watch("userId")
  private onUserIdChange(): void {
    this.getUserStats();
  }

  private mounted(): void {
    this.getNextLesson();
    this.getUserStats();
  }

  private get link() {
    return `/analytics/${this.userId}`;
  }

  private get score() {
    if (!this.userStat) {
      return 0;
    }
    const { score } = this.userStat;

    return score ? score.toFixed(2) : 0;
  }

  private get detectionAccuracy() {
    if (!this.userStat) {
      return 0;
    }
    const { detectionAccuracy } = this.userStat;
    return detectionAccuracy ? detectionAccuracy.toFixed(2) : 0;
  }

  private get timeToSelect() {
    if (!this.userStat) {
      return 0;
    }
    const { timeToSelect } = this.userStat;
    return timeToSelect ? timeToSelect.toFixed(2) : 0;
  }
}

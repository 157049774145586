
import { Component, Vue, Prop } from "vue-property-decorator";
import IBaseEducation from "@/types/IBaseEducation";
import { baseMediaUrl } from "@/api/media";

@Component({
  components: {},
})
export default class EducationCardTemplate extends Vue {
  @Prop({ default: false }) private readonly cardDisabled!: boolean;
  @Prop({ required: true }) private readonly data!: IBaseEducation;
  @Prop({ default: false }) private readonly isComplete!: boolean;
  @Prop({ default: true }) private readonly needEditButton!: boolean;
  
  private get mediaUrl() {
    return baseMediaUrl;
  }
  private get canManageCourses() {
    return this.$store.getters.canManageCourses;
  }
}


import { Component, Prop, Vue } from "vue-property-decorator";

@Component({
  components: {},
})
export default class DashboardCard extends Vue {
  @Prop({ required: true }) private readonly title!: string;
  @Prop({ required: true }) private readonly text!: string;
}


import { Component, Vue, Prop } from "vue-property-decorator";
import EducationCardTemplate from "@/components/EducationCardTemplate.vue";
import ILesson from "@/types/lesson";
import { ILessonResponse } from "@/types/user-result";
import { getLessonResults } from "@/api/api";
import TheLoader from "@/components/TheLoader.vue";

export type LessonStatus = "completed" | "current" | "next";

@Component({
  components: { TheLoader, EducationCardTemplate },
})
export default class LessonCard extends Vue {
  @Prop({ required: true }) private readonly lesson!: ILesson;
  @Prop({ default: true }) private readonly needLessonResult!: boolean;
  private result: ILessonResponse | null = null;
  private resultLoading = false;
  
  private get editLink() {
    const { courseID, id } = this.lesson;

    return `/courses/${courseID}/lessons-create/${id}`;
  }

  private goToEdit() {
    this.$router.push(this.editLink);
  }

  private get userId() {
    return this.$store.getters.userId;
  }

  private get userAnalyticLink() {
    return `/analytics/${this.userId}#${this.lesson.id}`;
  }

  private async loadResult() {
    if (!this.lesson.id) {
      return;
    }

    try {
      this.resultLoading = true;
      this.result = (await getLessonResults(this.lesson.id, this.userId))[0];
    } catch (e) {
      console.error(e);
    } finally {
      this.resultLoading = false;
    }
  }

  private mounted() {
    if (!this.lesson.complete) {
      return;
    }

    if (this.needLessonResult) {
      this.loadResult();
    }
  }

  private get score() {
    if (!this.result) {
      return 0;
    }
    const { score } = this.result;

    return score ? score.toFixed(2) : 0;
  }

  private get correctAnomalies() {
    if (!this.result) {
      return 0;
    }
    const { correctAnomalies } = this.result;
    return correctAnomalies ? correctAnomalies.toFixed(2) : 0;
  }

  private get averageDetectionTime() {
    if (!this.result) {
      return 0;
    }
    const { averageDetectionTime } = this.result;
    return averageDetectionTime ? averageDetectionTime.toFixed(2) : 0;
  }
}
